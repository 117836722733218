/* eslint-disable prettier/prettier */
import * as React from 'react';

import { programOptions } from '../form-data';
import { withFormContext } from './withReactHookForm';

export const LevelContext = React.createContext();
export const useLevelContext = () => React.useContext(LevelContext);
const useGCMSProgramByLevel = () => {
  // const data = useStaticQuery(graphql`
  //   {
  //     gcms {
  //       jsonContanier(where: { name: "ProgramByLevel_RegisterForm" }) {
  //         content
  //       }
  //     }
  //   }
  // `);
  // return data.gcms.jsonContanier.content;
  return {
    SAT: ['SAT'],
    TOEIC: ['TOEIC'],
    'GMAT/GRE': ['GMAT/GRE'],
    'Tiếng Anh giao tiếp': ['Communication 1', 'PRONUN'],
    'IELTS Offline Hồ Chí Minh': [
      'English 101',
      'PRE',
      'SINT',
      {
        type: 'regex',
        value: '^INT[0-9]$'
      },
      {
        type: 'regex',
        value: '^[0-9]\\.[0-9]$'
      }
    ],
    'IELTS Online Zoom xịn như offline': ['Online']
  };
};

const isMatchCondition = (condition, currentLevel) => {
  if (typeof condition === 'string') {
    return condition === currentLevel;
  } else {
    // expect to be object
    if (condition.type) {
      const regx = new RegExp(condition.value);
      return regx.test(currentLevel);
    }
  }
  return false;
};

const isLevelMatchCondition = (currentLevel, conditions) => {
  return conditions.some(condition =>
    isMatchCondition(condition, currentLevel)
  );
};

export const withProgramByLevel = Component => props => {
  const currentLevel = useLevelContext();
  const programByLevel = useGCMSProgramByLevel();
  let defaultProgramKey = programOptions[0].key;

  if (currentLevel && programByLevel) {
    const programByLevelResult = Object.entries(programByLevel).find(
      ([, conditions]) => isLevelMatchCondition(currentLevel, conditions)
    );
    if (programByLevelResult) {
      defaultProgramKey = programByLevelResult[0];
    }
  }
  const EnhancedComponent = withFormContext({
    defaultValues: {
      program: programOptions.find(item => item.key === defaultProgramKey)
    }
  })(Component);

  return <EnhancedComponent {...props} />;
};
