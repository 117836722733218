// FORM DATA
export const programOptions = [
  { key: 'IELTS Offline Hồ Chí Minh', value: 'IELTS Offline Hồ Chí Minh' },
  {
    key: 'IELTS Online Zoom xịn như offline',
    value: 'IELTS Online Zoom xịn như offline'
  },
  {
    key: 'Tiếng Anh giao tiếp',
    value: 'Tiếng Anh giao tiếp'
  },
  {
    key: 'TOEIC',
    value: 'TOEIC'
  },
  {
    key: 'SAT',
    value: 'SAT'
  },
  {
    key: 'GMAT/GRE',
    value: 'GMAT/GRE'
  },
  {
    key: 'Tiếng Anh giao tiếp',
    value: 'Tiếng Anh giao tiếp'
  }
];
export const IELTS_OFFLINE_PROGRAM_OPTION = programOptions[0];
export const SAT_PROGRAM_OPTION = programOptions[4];
export const TOEIC_PROGRAM_OPTION = programOptions[3];
export const GMAT_GRE_PROGRAM_OPTION = programOptions[5];

export const currentPositionOptions = [
  { key: 'Học sinh cấp 3', value: 'Học sinh cấp 3' },
  { key: 'Sinh viên đại học', value: 'Sinh viên đại học' },
  { key: 'Người đi làm', value: 'Người đi làm' },
  { key: 'Phụ huynh', value: 'Phụ huynh' }
];

export const toeicCenterOptions = [
  { key: 'DOL Bình Thạnh', value: 'DOL Bình Thạnh' },
  { key: 'DOL Tân Bình', value: 'DOL Tân Bình' }
];
export const centerOptions = [
  { key: 'DOL 3/2', value: 'DOL 3/2' },
  ...toeicCenterOptions
];

export const taGTPurposeToLearnOptions = [
  { key: 'Du học', value: 'Du học' },
  { key: 'Định cư', value: 'Định cư' },
  { key: 'Việc làm', value: 'Việc làm' }
];

export const ieltsPurposeToLearnOptions = [
  { key: 'Tuyển sinh đại học', value: 'Tuyển sinh đại học' },
  { key: 'Tốt nghiệp đại học', value: 'Tốt nghiệp đại học' },
  ...taGTPurposeToLearnOptions,
  { key: 'Tăng khả năng sử dụng TA', value: 'Tăng khả năng sử dụng TA' }
];

export const dolTargetScoreOptions = [
  { key: '5.0', value: '5.0' },
  { key: '6.0', value: '6.0' },
  { key: '7.0', value: '7.0' },
  { key: '8.0+', value: '8.0+' }
];

export const toeicTargetScoreOptions = [
  { key: '450', value: '450' },
  { key: '550', value: '550' },
  { key: '650', value: '650' },
  { key: '750+', value: '750+' }
];

export const satTargetScoreOptions = [
  { key: '1300', value: '1300' },
  { key: '1400', value: '1400' },
  { key: '1500', value: '1500' }
];

export const testIntendedTimeOptions = [
  { key: 'Sau 3 tháng', value: 'Sau 3 tháng' },
  { key: 'sau 6 tháng', value: 'Sau 6 tháng' },
  { key: '1 năm sau', value: '1 năm sau' },
  { key: 'Chưa có ý định', value: 'Chưa có ý định' }
];
